<template>
  <div id="app">
    <CountdownRedirect></CountdownRedirect>
  </div>
</template>

<script>
  import CountdownRedirect from './components/CountdownRedirect.vue';
  export default {
    name: 'App',
    components: {
      CountdownRedirect,
    },
  }
</script>

<style>
  body,
  html {
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
  }
</style>